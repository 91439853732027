import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Products from "./Components/Products";
import Gloves from "./Components/Gloves";
import NonWoven from "./Components/NonWoven";
import Plastic from "./Components/Plastic";
import Solo from "./Components/Solo";
import { db } from "./firebase";
import './App.css'

const Router = () => {
  const [gloves, setGloves] = useState([]);
  const [nonWovens, setNonWovens] = useState([]);
  const [plastics, setPlastics] = useState([]);
  useEffect(() => {
    db.collection("gloves").onSnapshot((snapshot) =>
      setGloves(snapshot.docs.map((doc) => doc.data()))
    );
  }, []);
  useEffect(() => {
    db.collection("nonWovens").onSnapshot((snapshot) =>
      setNonWovens(snapshot.docs.map((doc) => doc.data()))
    );
  }, []);
  useEffect(() => {
    db.collection("plastics").onSnapshot((snapshot) =>
      setPlastics(snapshot.docs.map((doc) => doc.data()))
    );
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/products/gloves" element={<Gloves />} />
        <Route exact path="/products/non-wovens" element={<NonWoven />} />
        <Route exact path="/products/plastic-products" element={<Plastic />} />
        {gloves.map((glove) => (
          <Route
            key={glove.url}
            exact
            path={`/${glove.url}`}
            element={<Solo contents={glove} />}
          />
        ))}
        {nonWovens.map((nonWoven) => (
          <Route
            key={nonWoven.url}
            exact
            path={`/${nonWoven.url}`}
            element={<Solo contents={nonWoven} />}
          />
        ))}
        {plastics.map((plastic) => (
          <Route
            key={plastic.url}
            exact
            path={`/${plastic.url}`}
            element={<Solo contents={plastic} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
