import React, { useState, useEffect } from "react";
import logo from "../images/icons/logo.png";
import darkl from "../images/icons/darkl.png";
import phone05 from "../images/phone05.png";
import phone02 from "../images/phone02.png";
import screenshots01 from "../images/screenshots01.jpg";
import "../css/font-face.css";
import "../vendor/font-awesome/css/font-awesome.min.css";
import "../vendor/linearicons/css/linearicons.css";
import "../vendor/bootstrap/bootstrap.min.css";
import "../vendor/animate.css/animate.min.css";
import "../vendor/css-hamburgers/hamburgers.min.css";
import "../vendor/slick/slick.css";
import "../vendor/animsition/animsition.min.css";
import "../vendor/lightbox2/css/lightbox.min.css";
import "../css/theme.css";
import Aos from "aos";
import "aos/dist/aos.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Carousel from "react-bootstrap/Carousel";
import { db } from "../firebase";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

function Home() {
  const [show, setShow] = useState(true);
  const [isMModalOpen, setIsMModalOpen] = useState(false);
  const [name, setName] = useState("");
  const handleClose = () => setShowM(false);
  const handleShow = () => setShowM(true);
  const [showM, setShowM] = useState(false);

  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  useEffect(() => {
    Aos.init({ duration: 900, delay: 300 });
  }, []);

  const submitRequest = (event) => {
    event.preventDefault();

    db.collection("messages")
      .add({
        name: event.target.name.value,
        number: event.target.number.value,
        email: event.target.email.value,
        message: event.target.msg.value,
        time: new Date(),
      })
      .then((result) => {
        console.log("Message Sent");
        setName(event.target.name.value);
        // alert(
        //   `Hi ${event.target.name.value}! Your Message has been sent successfully. Our team will contact you soon :-)`
        // );
        handleShow();
      })
      .catch((err) => alert(err.message));

    // sendEmail(event);

    document.getElementById("contact-form").reset();
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_sdet5ho",
  //       "template_nak9js7",
  //       e.target,
  //       "user_LtpiPM3ciXGrC7RB5T795"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };
  return (
    <div className="App">
      <Modal show={showM} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Message Sent!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center mb-8">
            Hi! Your Message has been sent successfully. Our team will contact
            you soon :-)
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        <header
          id="header"
          className={`header-absolute js-header unsticky ${
            show ? "section-header" : "section-header-scroll"
          }`}
        >
          <nav
            className={`navbar navbar-expand-lg ${
              show ? "navbar-light" : "nava-dark"
            }`}
          >
            <div className="container">
              <a className="navbar-logo" href="/">
                <img
                  className="logo-light"
                  src={logo}
                  alt="Snac"
                  className={`${!show ? "logo-off" : "hidden lg:block"}`}
                />
                <img className="logo-dark" src={darkl} alt="Snac" />
              </a>
              {/* <button
                className="navbar-toggler hamburger hamburger--slider"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button> */}
              <div className="inline-flex lg:hidden">
                {["top"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    {/* <Button onClick={() => setIsMModalOpen(true)}>{anchor}</Button> */}
                    {/* <IoIosMenu
                      size={30}
                      className=" text-yellow-400 opacity-80"
                      onClick={() => setIsMModalOpen(true)}
                    />
                     */}
                    <span className="hamburger-box">
                      <span
                        className="hamburger-inner"
                        onClick={() => setIsMModalOpen(true)}
                      />
                    </span>
                    <SwipeableDrawer
                      anchor={anchor}
                      open={isMModalOpen}
                      onClose={() => setIsMModalOpen(false)}
                    >
                      <div
                        className="row bg-gray-900"
                        style={{ textAlign: "center" }}
                      >
                        <div
                          className="col-lg col-md"
                          style={{
                            paddingBottom: ".2rem",
                            paddingTop: "1rem",
                          }}
                        >
                          <a
                            href="/"
                            className="text-gray-300 hover:no-underline hover:text-white"
                            style={{ textDecoration: "none" }}
                          >
                            HOME
                          </a>
                        </div>
                        <div
                          className="col-lg col-md"
                          style={{
                            paddingBottom: ".2rem",
                            paddingTop: ".2rem",
                          }}
                        >
                          <a
                            href="/products"
                            className="text-gray-300 hover:no-underline hover:text-white"
                            style={{ textDecoration: "none" }}
                          >
                            PRODUCTS
                          </a>
                        </div>
                        <div
                          className="col-lg col-md"
                          style={{
                            paddingBottom: ".2rem",
                            paddingTop: ".2rem",
                          }}
                        >
                          <a
                            href="/about"
                            className="text-gray-300 hover:no-underline hover:text-white"
                            style={{ textDecoration: "none" }}
                          >
                            ABOUT US
                          </a>
                        </div>
                        <div
                          className="col-lg col-md"
                          style={{
                            paddingBottom: ".5rem",
                            paddingTop: ".2rem",
                          }}
                        >
                          <a
                            href="/contact"
                            className="text-gray-300 hover:no-underline hover:text-white"
                            style={{ textDecoration: "none" }}
                          >
                            CONTACT
                          </a>
                        </div>
                        <button
                          style={{
                            width: "100%",
                            height: "2.5rem",
                            fontSize: "1.2rem",
                            color: "white",
                          }}
                          onClick={() => setIsMModalOpen(false)}
                          className="bg-blue-500"
                        >
                          Close
                        </button>
                        {/* <Button onClick={() => setIsMModalOpen(false)}>Close</Button> */}
                      </div>
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
              </div>
              <div
                className="navbar-collapse collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item hover:text-white">
                    <a className="nav-link hover:text-white" href="/">
                      HOME
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/products">
                      PRODUCTS
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/about">
                      ABOUT US
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/contact">
                      CONTACT
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        {/* Header end*/}
        {/* Intro start*/}
        <div
          className="section section-slide slide-fullscreen blasdkljg"
          id="home"
        >
          <div className="container">
            <div className="section-inner">
              <div className="row align-items-center">
                <div className="col-lg-8 col-md-8">
                  <div
                    className=""
                    data-aos="fade-up"
                    data-aos-delay="1000"
                    data-aos-duration="700"
                  >
                    <h3 className="caption-heading my-heading section-content-text">
                      Caring for Human safety and Hygiene.
                    </h3>
                    <p className="caption-text animated my-caption section-content-text">
                      Riyom International - World class service provider to
                      Medical Professionals, Pharmaceutical Companies, etc.
                    </p>
                    <div className="caption-button animated">
                      <a
                        className="au-btn au-btn-pill au-btn-border au-btn-icon m-r-5 mb-3"
                        href="/contact"
                      >
                        <i className="btn-icon fa fa-phone mr-2" />
                        <span className="btn-text">Let's Connect!</span>
                      </a>
                      <a
                        className="au-btn au-btn-pill au-btn-border au-btn-icon m-l-5 mb-3"
                        href="/products"
                      >
                        <i className="btn-icon fa fa-medkit mr-2" />
                        <span className="btn-text">
                          Check out our Products!
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4">
                  <div className=" animated">
                    <img
                      className="img-fluid"
                      src={phone07}
                      alt="App Phone"
                      data-aos="fade-left"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* Intro end*/}
        {/* Featured start*/}
        <section
          className="section section-featured bg-white p-t-85 p-b-30"
          id="intro"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="card iconbox-style-1">
                  <div className="card-image">
                    <i className="fa fa-truck" />
                  </div>
                  <div className="card-body">
                    <h4 className="card-title">
                      <a className="card-title-link" href="#">
                        Quick Shipping
                      </a>
                    </h4>
                    <p className="card-text">
                      We put a great emphasis on on-time delivery to the
                      customers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card iconbox-style-1">
                  <div className="card-image">
                    <i className="fa fa-star" />
                  </div>
                  <div className="card-body">
                    <h4 className="card-title">
                      <a className="card-title-link" href="#">
                        Top Notch Quality
                      </a>
                    </h4>
                    <p className="card-text">
                      Our team of qualified people examins every product &amp;
                      makes sure that it is perfect before it is shipped.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card iconbox-style-1">
                  <div className="card-image">
                    <i className="fa fa-plus-square" />
                  </div>
                  <div className="card-body">
                    <h4 className="card-title">
                      <a className="card-title-link" href="#">
                        Safe &amp; Hygienic Products
                      </a>
                    </h4>
                    <p className="card-text">
                      All our employees are trained at keeping the importance of
                      hygiene and safety in mind.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Featured end*/}
        {/* About start*/}
        <section className="section section-about bg-gray-50 pt-8 p-b-30">
          <div className="container">
            <div className="row align-items-md-center">
              <div className="col-md-6">
                <div className="section-content-image mr-20 mb-4">
                  <img
                    className="wow fadeInLeft img-fluid m-2  shadow-2xl rounded-lg"
                    src="https://i.ibb.co/6PcZ7vj/New-Project-4.jpg"
                    alt="Phone App"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.5s"
                    data-aos="fade-right"
                  />
                </div>
              </div>
              <div className="col-md-6 m-t-60 ">
                <div>
                  <h3 data-aos="fade-left" className="section-heading m-b-25">
                    About Riyom International.
                  </h3>
                </div>
                <p className="type-gray-1 m-b-40" data-aos="fade-left">
                  Riyom International is one of the largest importers of various
                  quality hygiene products including different types of gloves
                  and one of the top manufacturers of non woven &amp; plastic
                  disposable products in India.
                </p>
                <ul
                  className="v-list list-text-icon m-b-20"
                  data-aos="fade-left"
                >
                  <li className="iconbox iconbox-style-2">
                    <i className="iconbox-icon fa fa-check" />
                    <span className="iconbox-text">
                      Demand Based Range of Products
                    </span>
                  </li>
                  <li className="iconbox iconbox-style-2">
                    <i className="iconbox-icon fa fa-check" />
                    <span className="iconbox-text">Attention to Detail</span>
                  </li>
                  <li className="iconbox iconbox-style-2">
                    <i className="iconbox-icon fa fa-check" />
                    <span className="iconbox-text">
                      Best Customer Experience
                    </span>
                  </li>
                </ul>
                <a className="au-btn au-btn-pill au-btn-icon" href="/about">
                  <span className="btn-text">KNOW MORE</span>
                  <i className="btn-icon fa fa-chevron-right" />
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* About end*/}
        {/* About start*/}
        {/* <section className="section section-about-1 bg-white p-t-40 p-b-30 container">
          <div className="container">
            <div className="row align-items-md-center">
              <div className="col-md-6 m-t-50">
                <h3 className="section-heading m-b-30">It's Awesome</h3>
                <ul
                  className="v-list list-text-icon list-text-icon-accordion m-b-20"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <li className="iconbox iconbox-style-2 iconbox-style-2-with-text">
                    <a
                      className="iconbox-item-title"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse1"
                      aria-expanded="false"
                      aria-controls="collapse1"
                    >
                      <i className="iconbox-icon fa fa-camera" />
                      <span className="iconbox-text">Made with love</span>
                    </a>
                    <div
                      className="collapse show iconbox-item-text"
                      id="collapse1"
                      role="tabpanel"
                      aria-expanded="false"
                    >
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc mauris arcu, lobortis id interdum vitae, interdum
                        eget elit. Curabitur quis urna nulla. Suspendisse
                        potenti.
                      </p>
                    </div>
                  </li>
                  <li className="iconbox iconbox-style-2 iconbox-style-2-with-text">
                    <a
                      className="iconbox-item-title collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse2"
                      aria-expanded="false"
                      aria-controls="collapse2"
                    >
                      <i className="iconbox-icon fa fa-bluetooth-b" />
                      <span className="iconbox-text">
                        Model Design, engineered for you
                      </span>
                    </a>
                    <div
                      className="collapse iconbox-item-text"
                      id="collapse2"
                      role="tabpanel"
                      aria-expanded="false"
                    >
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc mauris arcu, lobortis id interdum vitae, interdum
                        eget elit. Curabitur quis urna nulla. Suspendisse
                        potenti.
                      </p>
                    </div>
                  </li>
                  <li className="iconbox iconbox-style-2 iconbox-style-2-with-text">
                    <a
                      className="iconbox-item-title collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      <i className="iconbox-icon fa fa-calendar" />
                      <span className="iconbox-text">
                        Fully responsive, mobile ready
                      </span>
                    </a>
                    <div
                      className="collapse iconbox-item-text"
                      id="collapse3"
                      role="tabpanel"
                      aria-expanded="false"
                    >
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc mauris arcu, lobortis id interdum vitae, interdum
                        eget elit. Curabitur quis urna nulla. Suspendisse
                        potenti.
                      </p>
                    </div>
                  </li>
                  <li className="iconbox iconbox-style-2 iconbox-style-2-with-text">
                    <a
                      className="iconbox-item-title collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse4"
                      aria-expanded="false"
                      aria-controls="collapse4"
                    >
                      <i className="iconbox-icon fa fa-bell-o" />
                      <span className="iconbox-text">
                        Color Schemes, very easy customize
                      </span>
                    </a>
                    <div
                      className="collapse iconbox-item-text"
                      id="collapse4"
                      role="tabpanel"
                      aria-expanded="false"
                    >
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc mauris arcu, lobortis id interdum vitae, interdum
                        eget elit. Curabitur quis urna nulla. Suspendisse
                        potenti.
                      </p>
                    </div>
                  </li>
                  <li className="iconbox iconbox-style-2 iconbox-style-2-with-text">
                    <a
                      className="iconbox-item-title collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse5"
                      aria-expanded="false"
                      aria-controls="collapse5"
                    >
                      <i className="iconbox-icon fa fa-credit-card" />
                      <span className="iconbox-text">Parallax backgrounds</span>
                    </a>
                    <div
                      className="collapse iconbox-item-text"
                      id="collapse5"
                      role="tabpanel"
                      aria-expanded="false"
                    >
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc mauris arcu, lobortis id interdum vitae, interdum
                        eget elit. Curabitur quis urna nulla. Suspendisse
                        potenti.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <div
                  className="section-content-image wow fadeInRight"
                  data-wow-duration="0.8s"
                  data-wow-delay="0.5s"
                >
                  <img
                    className="img-fluid"
                    src={phone06}
                    data-aos="fade-left"
                    alt="Phone App"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* About end*/}
        {/* Call to action start*/}
        <section
          className="section pos-relative section-cta bg-parallax p-t-90 p-b-90"
          style={{
            background: "url(images/cta-01.jpg) center center no-repeat",
          }}
        >
          <div className="bg-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="section-heading section-heading--center section-heading--light m-b-30">
                  ISO 9001-2008 CE certified
                </h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <p className="text-center type-gray-2 m-b-30">
                  All our quality services and best in-industrly products have
                  helped the us to acheive ISO 9001-2008 CE certification. This
                  quality management systems certification was provided to Riyom
                  International in 2012.
                </p>
              </div>
            </div>
            <div
              className="image-content justify-content-center m-b-50 pos-relative wow zoomIn"
              data-wow-duration="0.5s"
            >
              <img
                className="img-fluid mx-auto rounded-lg"
                src="https://i.ibb.co/Lh9NPqd/New-Project-3.jpg"
                alt="riyom certified with ISO certificate"
                data-aos="zoom-in"
              />
            </div>
            <div
              className="btn-group justify-content-center wow zoomIn"
              data-wow-delay="0.5s"
            >
              <a
                className="au-btn au-btn-pill au-btn-border au-btn-icon m-r-5"
                href="/about"
              >
                <span className="btn-text">KNOW MORE</span>
              </a>
            </div>
          </div>
        </section>
        {/* Call to action end*/}
        {/* Features start*/}
        <section
          className="section section-about-2 bg-gray-50 p-t-90 p-b-40"
          id="features"
        >
          <div className="container">
            <h3 className="section-heading section-heading--center section-heading--dark m-b-50">
              Why us?
            </h3>
            <div className="row section-content-inner justify-center">
              <div className="col">
                <ul className="v-list list-text-icon">
                  <li
                    className="iconbox iconbox-style-2 iconbox-style-2-with-text iconbox-style-2-with-text-lg iconbox-style-2-with-text-right wow fadeInLeft"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.2s"
                    data-aos="fade-right"
                  >
                    <span className="iconbox-item-title" href="#">
                      <span className="iconbox-text">
                        On-Demand Development
                      </span>
                      <i className="iconbox-icon fa fa-crop" />
                    </span>
                    <p className="collapse show iconbox-item-text lg:text-sm xl:text-lg text-justify">
                      We are always glad to develop any non-woven or plastic
                      disposable product that you do not find on our range of
                      products.
                    </p>
                  </li>
                  <li
                    className="iconbox iconbox-style-2 iconbox-style-2-with-text iconbox-style-2-with-text-lg iconbox-style-2-with-text-right wow fadeInLeft"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.4s"
                    data-aos="fade-right"
                  >
                    <span className="iconbox-item-title" href="#">
                      <span className="iconbox-text">Durable Products</span>
                      <i className="iconbox-icon fa fa-tablet" />
                    </span>
                    <p className="collapse show iconbox-item-text text-justify lg:text-sm xl:text-lg">
                      Our products such as non-woven shoe covers, sleeves, etc
                      are made from strong, comfortable &amp; durable material
                      to make it long-lasting.
                    </p>
                  </li>
                  <li
                    className="iconbox iconbox-style-2 iconbox-style-2-with-text iconbox-style-2-with-text-lg iconbox-style-2-with-text-right wow fadeInLeft"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.6s"
                    data-aos="fade-right"
                  >
                    <span className="iconbox-item-title" href="#">
                      <span className="iconbox-text">
                        Experienced in Industry
                      </span>
                      <i className="iconbox-icon fa fa-star" />
                    </span>
                    <p className="collapse show iconbox-item-text text-justify lg:text-sm xl:text-lg">
                      Riyom International has been manufacturing and serving
                      medical professionals since 2012.
                    </p>
                  </li>
                </ul>
              </div>
              <div
                className="col-4 image-content wow fadeInUp"
                data-wow-duration="1s"
              >
                <img
                  src="https://i.ibb.co/0FYPm0P/New-Project-5.jpg"
                  alt="#"
                  className="mx-auto rounded-xl shadow-lg"
                  data-aos="zoom-out"
                />
              </div>
              <div className="col">
                <ul className="v-list list-text-icon list-text-icon-invert">
                  <li
                    className="iconbox iconbox-style-2 iconbox-style-2-with-text iconbox-style-2-with-text-lg wow fadeInRight"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.2s"
                    data-aos="fade-left"
                  >
                    <span className="iconbox-item-title" href="#">
                      <i className="iconbox-icon fa fa-codepen" />
                      <span className="iconbox-text">
                        Best Interests at Heart
                      </span>
                    </span>
                    <p className="collapse show iconbox-item-text text-left lg:text-sm xl:text-lg">
                      Riyom International has their client's best interests at
                      heart to provide them hygienic and safe products.
                    </p>
                  </li>
                  <li
                    className="iconbox iconbox-style-2 iconbox-style-2-with-text iconbox-style-2-with-text-lg wow fadeInRight"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.4s"
                    data-aos="fade-left"
                  >
                    <span className="iconbox-item-title" href="#">
                      <i className="iconbox-icon fa fa-pencil" />
                      <span className="iconbox-text">
                        Creative Research Teams
                      </span>
                    </span>
                    <p className="collapse show iconbox-item-text text-left lg:text-sm xl:text-lg">
                      We have a creative team of dedicated researchers who are
                      constantly researching new ways to creatively equip our
                      products with hygiene &amp; safety.
                    </p>
                  </li>
                  <li
                    className="iconbox iconbox-style-2 iconbox-style-2-with-text iconbox-style-2-with-text-lg wow fadeInRight"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.6s"
                    data-aos="fade-left"
                  >
                    <span className="iconbox-item-title" href="#">
                      <i className="iconbox-icon fa fa-heart" />
                      <span className="iconbox-text">FRIENDLY SUPPORT</span>
                    </span>
                    <p className="collapse show iconbox-item-text text-left lg:text-sm xl:text-lg">
                      We are always there to provide any support you need from
                      us regarding our products.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* Features end*/}
        {/* Statistic start*/}
        <section
          className="section pos-relative section-statistic bg-parallax p-t-135 p-b-100"
          style={{
            background: "url(images/statistic-01.jpg) center center no-repeat",
          }}
        >
          <div className="bg-overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md">
                <div className="iconbox iconbox-style-3">
                  <CountUp start={0} end={9} duration={1.5} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span
                          ref={countUpRef}
                          className="iconbox-number counterUp"
                        />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <h4 className="iconbox-title">yrs of Experience</h4>
                </div>
              </div>
              <div className="col-md">
                <div className="iconbox iconbox-style-3">
                  <CountUp start={0} end={40} duration={1.5} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span
                          ref={countUpRef}
                          className="iconbox-number counterUp"
                        />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <h4 className="iconbox-title">EMPLOYEES</h4>
                </div>
              </div>
              <div className="col-md">
                <div className="iconbox iconbox-style-3">
                  <CountUp start={0} end={4} duration={1.5} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span
                          ref={countUpRef}
                          className="iconbox-number counterUp"
                        />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <h4 className="iconbox-title">AWARDS</h4>
                </div>
              </div>
              <div className="col-md">
                <div className="iconbox iconbox-style-3">
                  <CountUp start={0} end={21} duration={1.5} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span
                          ref={countUpRef}
                          className="iconbox-number counterUp"
                        />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <h4 className="iconbox-title">Products</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Statistic end*/}
        {/* App Screenshots start*/}
        {/* <section className="section section-screenshots p-t-90 p-b-90 bg-gray-50">
          <div className="container">
            <h3 className="section-heading section-heading--center section-heading--dark m-b-50">
              Products
            </h3>
            <ul
              className="list-reset js-slick row"
              data-slick-lg={1}
              data-slick-dots="true"
              data-slick-dotsclass="slick-dots dots-style-1"
            >
              <li className="col">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="card card-style-1">
                      <div className="card-image">
                        <a
                          className="card-overlay"
                          href="images/screenshots-01.jpg"
                          data-lightbox="roadtrip"
                        />
                        <i className="card-icon lnr lnr-plus-circle" />
                        <img src={screenshots01} alt="app screenshots" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="card card-style-1">
                      <div className="card-image">
                        <a
                          className="card-overlay"
                          href="images/screenshots-02.jpg"
                          data-lightbox="roadtrip"
                        />
                        <i className="card-icon lnr lnr-plus-circle" />
                        <img src={screenshots01} alt="app screenshots" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="card card-style-1">
                      <div className="card-image">
                        <a
                          className="card-overlay"
                          href="images/screenshots-03.jpg"
                          data-lightbox="roadtrip"
                        />
                        <i className="card-icon lnr lnr-plus-circle" />
                        <img src={screenshots01} alt="app screenshots" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="card card-style-1">
                      <div className="card-image">
                        <a
                          className="card-overlay"
                          href="images/screenshots-04.jpg"
                          data-lightbox="roadtrip"
                        />
                        <i className="card-icon lnr lnr-plus-circle" />
                        <img src={screenshots01} alt="app screenshots" />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section> */}
        <section className="text-gray-600 body-font">
          <div className="container px-5 py-16 mx-auto">
            <h3 className="section-heading section-heading--center section-heading--dark m-b-50">
              Products
            </h3>
            {/* <div className="flex flex-wrap w-full mb-20">
              <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
                  Pitchfork Kickstarter Taxidermy
                </h1>
                <div className="h-1 w-20 bg-indigo-500 rounded" />
              </div>
              <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
                Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
                gentrify, subway tile poke farm-to-table. Franzen you probably
                haven't heard of them man bun deep jianbing selfies heirloom
                prism food truck ugh squid celiac humblebrag.
              </p>
            </div> */}
            <div className="flex flex-wrap -m-4">
              <div className="xl:w-1/4 md:w-1/2 p-4">
                <div className="bg-gray-100 p-6 rounded-lg">
                  <a
                    href="/latex-surgical-gloves"
                    className="hover:text-gray-900"
                  >
                    <img
                      className="h-40 rounded w-full object-cover object-center mb-6"
                      src="https://i.ibb.co/jRncqVt/New-Project-7.jpg"
                      alt="content"
                    />
                    <h2 className="text-lg text-gray-900 font-medium title-font ">
                      Latex Surgical Gloves
                    </h2>
                    <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font mb-4">
                      (Powder &amp; Powder-free)
                    </h3>
                    {/* <p className="leading-relaxed text-base">
                    Fingerstache flexitarian street art 8-bit waistcoat.
                    Distillery hexagon disrupt edison bulbche.
                  </p> */}
                    <a className="text-indigo-500 inline-flex items-center mt-3">
                      View product
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7" />
                      </svg>
                    </a>
                  </a>
                </div>
              </div>
              <div className="xl:w-1/4 md:w-1/2 p-4">
                <div className="bg-gray-100 p-6 rounded-lg">
                  <a
                    href="/nitrile-examination-gloves"
                    className="hover:text-gray-900"
                  >
                    <img
                      className="h-40 rounded w-full object-cover object-center mb-6"
                      src="https://i.ibb.co/ckjNRFg/New-Project-9.jpg"
                      alt="content"
                    />
                    <h2 className="text-lg text-gray-900 font-medium title-font ">
                      Nitrile Examination Gloves
                    </h2>
                    <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font mb-4">
                      (Powder-Free)
                    </h3>
                    {/* <p className="leading-relaxed text-base">
                      Fingerstache flexitarian street art 8-bit waistcoat.
                      Distillery hexagon disrupt edison bulbche.
                    </p> */}
                    <a className="text-indigo-500 inline-flex items-center mt-3">
                      View product
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7" />
                      </svg>
                    </a>
                  </a>
                </div>
              </div>
              <div className="xl:w-1/4 md:w-1/2 p-4">
                <div className="bg-gray-100 p-6 rounded-lg">
                  <a href="/plastic-gloves" className="hover:text-gray-900">
                    <img
                      className="h-40 rounded w-full object-cover object-center mb-6"
                      src="https://i.ibb.co/rMDPMsZ/New-Project-18.jpg"
                      alt="content"
                    />
                    {/* <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">
                    SUBTITLE
                  </h3> */}
                    <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                      Plastic Gloves
                    </h2>
                    {/* <p className="leading-relaxed text-base">
                      Fingerstache flexitarian street art 8-bit waistcoat.
                      Distillery hexagon disrupt edison bulbche.
                    </p> */}
                    <a className="text-indigo-500 inline-flex items-center mt-3">
                      View product
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7" />
                      </svg>
                    </a>
                  </a>
                </div>
              </div>
              <div className="xl:w-1/4 md:w-1/2 p-4">
                <div className="bg-gray-100 p-6 rounded-lg">
                  <a href="/vinyl-gloves" className="hover:text-gray-900">
                    <img
                      className="h-40 rounded w-full object-cover object-center mb-6"
                      src="https://i.ibb.co/ynJg14g/New-Project-17.jpg"
                      alt="content"
                    />
                    {/* <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">
                    SUBTITLE
                  </h3> */}
                    <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                      Vinyl Gloves
                    </h2>
                    {/* <p className="leading-relaxed text-base">
                      Fingerstache flexitarian street art 8-bit waistcoat.
                      Distillery hexagon disrupt edison bulbche.
                    </p> */}
                    <a className="text-indigo-500 inline-flex items-center mt-3">
                      View product
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7" />
                      </svg>
                    </a>
                  </a>
                </div>
              </div>
              <a
                href="/products"
                className="main-btn btn-hover border-btn wow fadeInUp mx-auto mb-2 mt-5"
                data-wow-delay=".6s"
              >
                Check out more Products!
              </a>
            </div>
          </div>
        </section>

        {/* App Screenshots end*/}
        {/* Video start*/}
        <div className="section section-video pos-relative bg-parallax blassdkljg">
          <div className="bg-overlay" />

          <div className="container">
            <div className="section-content">
              <p className="quote text-4xl lg:text-6xl">
                "The Greatest Wealth is your Health..."
              </p>
            </div>
          </div>
        </div>
        {/* Video end*/}
        {/* Pricing Tables start*/}
        {/* <section
          className="section section-price-table p-t-90 p-b-90 bg-gray-50"
          id="pricing"
        >
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-12">
                <h3 className="section-heading section-heading--center section-heading--dark m-b-50">
                  Pricing Tables
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="card card-price-table">
                  <div className="card-body">
                    <h4 className="card-title">BASIC</h4>
                    <div className="card-icon">
                      <i className="fa fa-cogs" />
                    </div>
                    <div className="card-text-price">
                      <span className="text-lg">$3.99</span>
                      <span className="text-sm">/mo</span>
                    </div>
                    <a className="au-btn au-btn-pill card-btn" href="#">
                      CHOOSE PLAN
                    </a>
                    <ul className="v-list">
                      <li>Security Suite</li>
                      <li>Anaylitcs integration</li>
                      <li>Social media integration</li>
                      <li>
                        <span className="type--bold">10 hours</span>
                        <span>of support</span>
                      </li>
                      <li>
                        <span className="type--bold">5GB</span>
                        <span>of Storage</span>
                      </li>
                      <li>
                        <span>Up to</span>
                        <span className="type--bold">10 Users</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card card-price-table card-price-table--featured">
                  <img className="card-tag" src={iccardtag} alt="tag" />
                  <div className="card-body">
                    <h4 className="card-title">PREMIUM</h4>
                    <div className="card-icon">
                      <i className="fa fa-database" />
                    </div>
                    <div className="card-text-price">
                      <span className="text-lg">$3.990</span>
                      <span className="text-sm">/mo</span>
                    </div>
                    <a className="au-btn au-btn-pill card-btn" href="#">
                      CHOOSE PLAN
                    </a>
                    <ul className="v-list">
                      <li>Security Suite</li>
                      <li>Anaylitcs integration</li>
                      <li>Social media integration</li>
                      <li>
                        <span className="type--bold">10 hours</span>
                        <span>of support</span>
                      </li>
                      <li>
                        <span className="type--bold">5GB</span>
                        <span>of Storage</span>
                      </li>
                      <li>
                        <span>Up to</span>
                        <span className="type--bold">10 Users</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card card-price-table">
                  <div className="card-body">
                    <h4 className="card-title">BASIC</h4>
                    <div className="card-icon">
                      <i className="fa fa-cogs" />
                    </div>
                    <div className="card-text-price">
                      <span className="text-lg">$3.99</span>
                      <span className="text-sm">/mo</span>
                    </div>
                    <a className="au-btn au-btn-pill card-btn" href="#">
                      CHOOSE PLAN
                    </a>
                    <ul className="v-list">
                      <li>Security Suite</li>
                      <li>Anaylitcs integration</li>
                      <li>Social media integration</li>
                      <li>
                        <span className="type--bold">10 hours</span>
                        <span>of support</span>
                      </li>
                      <li>
                        <span className="type--bold">5GB</span>
                        <span>of Storage</span>
                      </li>
                      <li>
                        <span>Up to</span>
                        <span className="type--bold">10 Users</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* Pricing Tables end*/}
        {/* FAQs start*/}
        {/* <section className="section section-faqs bg-white p-t-90 p-b-90">
          <div className="container">
            <div className="row align-items-md-center">
              <div className="col-md-6">
                <h3 className="section-heading section-heading--dark m-b-50">
                  Frequently Asked Questions
                </h3>
                <ul
                  className="v-list mx-auto"
                  id="accordion1"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <li className="card card-faqs">
                    <a
                      className="card-title"
                      data-toggle="collapse"
                      data-parent="#accordion1"
                      href="#collapse6"
                      aria-expanded="false"
                      aria-controls="collapse6"
                    >
                      <span className="card-title-text">
                        What nulla accumsan libero ac elit mattis?
                      </span>
                      <span className="card-title-toggle" />
                    </a>
                    <div
                      className="card-body collapse show"
                      id="collapse6"
                      role="tabpanel"
                      aria-expanded="false"
                    >
                      <p className="card-text">
                        Fusce ornare mi vel risus porttitor dignissim. Nunc eget
                        risus at ipsum blandit ornare vel sed velit. Proin
                        gravida arcu nisl, a dignissim mauris placerat id.
                        Vivamus interdum urna at sapien varius elementum.
                        Suspendisse ut mi felis et interdum libero lacinia vel.
                      </p>
                    </div>
                  </li>
                  <li className="card card-faqs">
                    <a
                      className="card-title collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion1"
                      href="#collapse7"
                      aria-expanded="false"
                      aria-controls="collapse7"
                    >
                      <span className="card-title-text">
                        Mauris euismod est elit, vel mattis est dapibus?
                      </span>
                      <span className="card-title-toggle" />
                    </a>
                    <div
                      className="card-body collapse"
                      id="collapse7"
                      role="tabpanel"
                      aria-expanded="false"
                    >
                      <p className="card-text">
                        Fusce ornare mi vel risus porttitor dignissim. Nunc eget
                        risus at ipsum blandit ornare vel sed velit. Proin
                        gravida arcu nisl, a dignissim mauris placerat id.
                        Vivamus interdum urna at sapien varius elementum.
                        Suspendisse ut mi felis et interdum libero lacinia vel.
                      </p>
                    </div>
                  </li>
                  <li className="card card-faqs">
                    <a
                      className="card-title collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion1"
                      href="#collapse8"
                      aria-expanded="false"
                      aria-controls="collapse8"
                    >
                      <span className="card-title-text">
                        Nullam vitae volutpat risus?
                      </span>
                      <span className="card-title-toggle" />
                    </a>
                    <div
                      className="card-body collapse"
                      id="collapse8"
                      role="tabpanel"
                      aria-expanded="false"
                    >
                      <p className="card-text">
                        Fusce ornare mi vel risus porttitor dignissim. Nunc eget
                        risus at ipsum blandit ornare vel sed velit. Proin
                        gravida arcu nisl, a dignissim mauris placerat id.
                        Vivamus interdum urna at sapien varius elementum.
                        Suspendisse ut mi felis et interdum libero lacinia vel.
                      </p>
                    </div>
                  </li>
                  <li className="card card-faqs">
                    <a
                      className="card-title collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion1"
                      href="#collapse9"
                      aria-expanded="false"
                      aria-controls="collapse9"
                    >
                      <span className="card-title-text">
                        What lorem ipsum dolor sit amet?
                      </span>
                      <span className="card-title-toggle" />
                    </a>
                    <div
                      className="card-body collapse"
                      id="collapse9"
                      role="tabpanel"
                      aria-expanded="false"
                    >
                      <p className="card-text">
                        Fusce ornare mi vel risus porttitor dignissim. Nunc eget
                        risus at ipsum blandit ornare vel sed velit. Proin
                        gravida arcu nisl, a dignissim mauris placerat id.
                        Vivamus interdum urna at sapien varius elementum.
                        Suspendisse ut mi felis et interdum libero lacinia vel.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <div className="section-content-image">
                  <img
                    className="wow fadeInRight mx-auto"
                    data-aos="fade-left"
                    src={phone02}
                    alt="Phone"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.5s"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* FAQs end*/}
        {/* Partners start*/}
        <section className="section section-partner bg-white p-t-90 p-b-100">
          <div className="container">
            <h3 className="section-heading section-heading--center m-b-45">
              Our Clients
            </h3>
            <div className="row justify-content-center mx-auto">
              <div className="col-md-9">
                <p className="text-center section-content-text m-b-50">
                  Riyom International has served several major companies and
                  hospitals in the past. Some of them are as following:-
                </p>
              </div>
            </div>
            <div className="row text-center  justify-content-center">
              <div className="iconbox iconbox-style-4 col-5 col-md-3 col-lg-2 m-1 md:m-3">
                <img
                  src="https://i.ibb.co/K2sDqbW/New-Project-3.jpg"
                  alt="McDonald's"
                />
              </div>

              <div className="iconbox iconbox-style-4 col-5 col-md-3 col-lg-2 m-1 md:m-3">
                <img
                  src="https://i.ibb.co/4N0vr1G/New-Project-4.jpg"
                  alt="Indigo"
                />
              </div>

              <div className="iconbox iconbox-style-4 col-5 col-md-3 col-lg-2 m-1 md:m-3">
                <img
                  src="https://i.ibb.co/G2pwqGV/New-Project-5.jpg"
                  alt="Fortis"
                />
              </div>

              <div className="iconbox iconbox-style-4 col-5 col-md-3 col-lg-2 m-1 md:m-3">
                <img
                  src="https://i.ibb.co/cFsxMSq/New-Project-6.jpg"
                  alt="Max"
                />
              </div>
              <div className="iconbox iconbox-style-4 col-5 col-md-3 col-lg-2 m-1 md:m-3">
                <img
                  src="https://i.ibb.co/tKSfdv7/New-Project-7.jpg"
                  alt="Medanta"
                />
              </div>
              <div className="iconbox iconbox-style-4 col-5 col-md-3 col-lg-2 m-1 md:m-3">
                <img
                  src="https://i.ibb.co/ph0t8yK/New-Project-8.jpg"
                  alt="SpiceJet"
                />
              </div>
              <div className="iconbox iconbox-style-4 col-5 col-md-3 col-lg-2 m-1 md:m-3">
                <img
                  src="https://i.ibb.co/16WSVJw/New-Project-9.jpg"
                  alt="Air India"
                />
              </div>
            </div>
          </div>
        </section>
        {/* Partners start*/}
        {/* Testimonials start*/}
        <section
          className="section section-testimonials pos-relative bg-parallax p-t-100 p-b-100"
          id="testimonials"
          style={{
            background:
              "url(images/testimonials-01.jpg) center center no-repeat",
          }}
        >
          <div className="bg-overlay" />
          <div className="container">
            <h3 className="section-heading section-heading--center section-heading--light m-b-45">
              Testimonials
            </h3>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <Carousel variant="dark" interval={3500}>
                  {/* <Carousel.Item>
                    <div className="flex flex-wrap -m-4">
                      <div className="p-4  w-full">
                        <div className="h-full bg-gray-100 p-8 rounded">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            className="block w-5 h-5 text-gray-400 mb-4"
                            viewBox="0 0 975.036 975.036"
                          >
                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z" />
                          </svg>
                          <p
                            className="leading-relaxed mb-6"
                            data-aos="fade-down"
                          >
                            Synth chartreuse iPhone lomo cray raw denim brunch
                            everyday carry neutra before they sold out fixie
                            90's microdosing. Tacos pinterest fanny pack venmo,
                            post-ironic heirloom try-hard pabst authentic
                            iceland.
                          </p>
                          <a className="inline-flex items-center" data-aos="fade-up">
                            <img
                              alt="testimonial"
                              src="https://dummyimage.com/106x106"
                              className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                            />
                            <span className="flex-grow flex flex-col pl-4">
                              <span className="title-font font-medium text-gray-900">
                                Holden Caulfield
                              </span>
                              <span className="text-gray-500 text-sm">
                                UI DEVELOPER
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>

                  </Carousel.Item> */}
                  <Carousel.Item>
                    <div class="container px-5 py-8 mx-auto bg-gray-100 rounded-lg">
                      <div class="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="inline-block w-8 h-8 text-gray-400 mb-8"
                          viewBox="0 0 975.036 975.036"
                        >
                          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                        <p class="leading-relaxed text-lg" data-aos="fade-down">
                          Riyom International is exactly what I've been looking
                          for. Not able to tell you how satisfied I am with the
                          service. It's just amazing.
                        </p>
                        <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                        <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">
                          R.K. Singla
                        </h2>
                        <p class="text-gray-500">Senior Surgeon</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div class="container px-5 py-8 mx-auto bg-gray-100 rounded-lg">
                      <div class="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="inline-block w-8 h-8 text-gray-400 mb-8"
                          viewBox="0 0 975.036 975.036"
                        >
                          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                        <p class="leading-relaxed text-lg" data-aos="fade-down">
                          Riyom International is truly upstanding and the
                          product quality is 100%. Good Work, Keep it up!
                        </p>
                        <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                        <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">
                          Bharti Joshi
                        </h2>
                        <p class="text-gray-500">ENT Specialist</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div class="container px-5 py-8 mx-auto bg-gray-100 rounded-lg">
                      <div class="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="inline-block w-8 h-8 text-gray-400 mb-8"
                          viewBox="0 0 975.036 975.036"
                        >
                          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                        <p class="leading-relaxed text-lg" data-aos="fade-down">
                          I loved Riyom's on-time service. Great job, I will
                          definitely be ordering again!
                        </p>
                        <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                        <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">
                          Tarun Arora
                        </h2>
                        <p class="text-gray-500">Physician</p>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </section>
        {/* Testimonials end*/}
        {/* Download start*/}
        {/* <section
          className="section section-download-app bg-white p-t-90 p-b-40"
          id="download"
        >
          <div className="container">
            <h3 className="section-heading section-heading--center m-b-45">
              Download Awesome App Now
            </h3>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <p className="text-center m-b-30">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  mauris arcu, lobortis id interdum vitae, interdum eget elit.
                  Curabitur quis urna nulla. Suspendisse potenti. Duis suscipit
                  ultrices maximus.
                </p>
              </div>
            </div>
            <div
              className="section-btn-group m-b-50 wow zoomIn"
              data-wow-delay="0.6s"
            >
              <a href="#">
                <img src="images/icons/ic-btn-appstore.png" alt="Appstore" />
              </a>
              <a href="#">
                <img
                  src="images/icons/ic-btn-googleplay.png"
                  alt="Google Play"
                />
              </a>
            </div>
            <div className="section-content-image">
              <img
                className="img-fluid wow fadeInUp"
                src="images/phone-04.png"
                alt="#"
                data-wow-delay="0.3s"
              />
            </div>
          </div>
        </section> */}
        {/* Download end*/}
        {/* Team start*/}
        {/* <section
          className="section section-team bg-gray-50 p-t-90 p-b-40"
          id="team"
        >
          <div className="container">
            <h3 className="section-heading section-heading--center m-b-45">
              Meet The Team
            </h3>
            <ul className="row list-reset">
              <li className="col-lg-4 col-md-6 col-sm-6">
                <div className="box box-style-2">
                  <div className="box-image">
                    <img src="images/member-01.jpg" alt="Philip Barnett" />
                    <a className="bg-overlay" href="#" />
                    <ul className="list-socials">
                      <li className="list-item">
                        <a className="fa fa-facebook" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-twitter" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-dribbble" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-behance" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-linkedin" href="#" />
                      </li>
                    </ul>
                  </div>
                  <div className="box-body">
                    <h4 className="box-title">
                      <a href="#">Philip Barnett</a>
                    </h4>
                    <p className="box-author-job">UI/UX Designer</p>
                  </div>
                </div>
              </li>
              <li className="col-lg-4 col-md-6 col-sm-6">
                <div className="box box-style-2">
                  <div className="box-image">
                    <img src="images/member-02.jpg" alt="Philip Barnett" />
                    <a className="bg-overlay" href="#" />
                    <ul className="list-socials">
                      <li className="list-item">
                        <a className="fa fa-facebook" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-twitter" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-dribbble" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-behance" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-linkedin" href="#" />
                      </li>
                    </ul>
                  </div>
                  <div className="box-body">
                    <h4 className="box-title">
                      <a href="#">Philip Barnett</a>
                    </h4>
                    <p className="box-author-job">iOS Developer</p>
                  </div>
                </div>
              </li>
              <li className="col-lg-4 col-md-6 col-sm-6">
                <div className="box box-style-2">
                  <div className="box-image">
                    <img src="images/member-03.jpg" alt="Philip Barnett" />
                    <a className="bg-overlay" href="#" />
                    <ul className="list-socials">
                      <li className="list-item">
                        <a className="fa fa-facebook" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-twitter" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-dribbble" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-behance" href="#" />
                      </li>
                      <li className="list-item">
                        <a className="fa fa-linkedin" href="#" />
                      </li>
                    </ul>
                  </div>
                  <div className="box-body">
                    <h4 className="box-title">
                      <a href="#">Philip Barnett</a>
                    </h4>
                    <p className="box-author-job">iOS Developer</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section> */}
        {/* Team end*/}
        {/* Subcribe start*/}
        {/* <section
          className="section section-cta-1 pos-relative bg-parallax p-t-100 p-b-100"
          style={{
            background: "url(images/cta-02.jpg) center center no-repeat",
          }}
        >
          <div className="bg-overlay" />
          <div className="container">
            <h3 className="section-heading section-heading--center section-heading--light m-b-20">
              Subcribe To Newsletters
            </h3>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <p className="text-center section-content-text m-b-50">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  mauris arcu, lobortis id interdum vitae, interdum eget elit.
                  Curabitur quis urna nulla. Suspendisse potenti. Duis suscipit
                  ultrices maximus.
                </p>
              </div>
            </div>
            <form className="section-form-group">
              <div className="input-item">
                <input
                  className="au-input au-input-pill"
                  type="text"
                  placeholder="Your Name"
                />
              </div>
              <div className="input-item">
                <input
                  className="au-input au-input-pill"
                  type="text"
                  placeholder="Your Email"
                />
              </div>
              <div className="input-item">
                <input
                  className="au-btn au-btn-pill"
                  type="submit"
                  defaultValue="SUBCRIBE"
                />
              </div>
            </form>
          </div>
        </section> */}
        {/* Subcribe end*/}
        {/* Blog start*/}
        {/* <section
          className="section section-news bg-gray-50 p-t-90 p-b-100"
          id="blog"
        >
          <div className="container">
            <h3 className="section-heading section-heading--center m-b-45">
              Latest Form News
            </h3>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="card card-style-2">
                  <a
                    className="card-link"
                    href="#"
                    data-toggle="modal"
                    data-target="#blogModal"
                  />
                  <div className="card-image">
                    <img
                      className="img-fluid"
                      src="images/blog-01.jpg"
                      alt="Best Seller of the Week: 600+ UI Elements design for Web
"
                    />
                  </div>
                  <div className="card-body">
                    <h4 className="news-title">
                      <a href="#">
                        Best Seller of the Week: 600+ UI Elements design for Web
                      </a>
                    </h4>
                    <span className="news-date">July 18, 2017</span>
                    <a className="news-link" href="#">
                      CONTINUE READING
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card card-style-2">
                  <a
                    className="card-link"
                    href="#"
                    data-toggle="modal"
                    data-target="#blogModal"
                  />
                  <div className="card-image">
                    <img
                      className="img-fluid"
                      src="images/blog-02.jpg"
                      alt="Products of the Week: Designed for companies, small teams and freelancers
"
                    />
                  </div>
                  <div className="card-body">
                    <h4 className="news-title">
                      <a href="#">
                        Products of the Week: Designed for companies, small
                        teams and freelancers
                      </a>
                    </h4>
                    <span className="news-date">July 10, 2017</span>
                    <a className="news-link" href="#">
                      CONTINUE READING
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card card-style-2">
                  <a
                    className="card-link"
                    href="#"
                    data-toggle="modal"
                    data-target="#blogModal"
                  />
                  <div className="card-image">
                    <img
                      className="img-fluid"
                      src="images/blog-03.jpg"
                      alt="Expert Advice: 10 Best Popular  WordPress Theme of 2017
"
                    />
                  </div>
                  <div className="card-body">
                    <h4 className="news-title">
                      <a href="#">
                        Expert Advice: 10 Best Popular WordPress Theme of 2017
                      </a>
                    </h4>
                    <span className="news-date">June 29, 2017</span>
                    <a className="news-link" href="#">
                      CONTINUE READING
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-btn-group p-t-50">
              <a className="au-btn au-btn-pill au-btn-icon" href="#">
                <span className="btn-text">VIEW ALL BLOG POSTS</span>
                <span className="btn-icon fa fa-chevron-right" />
              </a>
            </div>
          </div>
        </section> */}
        {/* Blog end*/}
        {/* BLOG MODAL*/}
        <div
          className="blog-modal modal fade"
          id="blogModal"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="container">
                <div className="modal-header">
                  <button
                    className="btn-close au-btn au-btn-border"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span>Close</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="article-wrapper article article-2">
                    <h3 className="article-title">
                      7 Questions to Find Out What Clients Really Want
                    </h3>
                    <div className="article-info m-b-25">
                      <span>by</span>
                      <a className="author" href="#">
                        John Doe
                      </a>
                      <span className="date">August 28, 2018</span>
                      <ul className="subject">
                        <li>
                          <a href="#">Web Design</a>
                        </li>
                      </ul>
                      <a className="totals-comments" href="#">
                        1 Comments
                      </a>
                    </div>
                    <div className="article-image">
                      <img
                        className="img-fluid"
                        src="images/blog-single-01.jpg"
                        alt="#"
                      />
                    </div>
                    <p className="text-block">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Donec et tempus quam. Aliquam eu tellus urna. Pellentesque
                      consectetur et nisl vel pulvinar. Vestibulum ante ipsum
                      primis in faucibus orci luctus et ultrices posuere cubilia
                      Curae; Donec nec condimentum ipsum. Donec sollicitudin
                      gravida felis. Aliquam eu augue viverra, tempus tellus in,
                      dictum orci. Praesent volutpat porta vehicula.
                      Pellentesque a mauris sit amet diam vehicula tincidunt eu
                      ac ante. Phasellus in metus lectus. Duis scelerisque velit
                      eget nisl tempor aliquet. Curabitur est risus, facilisis
                      non
                    </p>
                    <blockquote className="article-blockquote">
                      <p className="text-block">
                        Cras auctor bibendum lorem vel sodales. Nulla commodo
                        velit ac turpis ullamcorper placerat.
                      </p>
                      <span className="text-block text-grey">
                        JOHN SMITH, ENVATO
                      </span>
                    </blockquote>
                    <p className="text-block">
                      Cras et urna ipsum. Fusce eget libero nibh. Vivamus a
                      sapien libero. Class aptent taciti sociosqu ad litora
                      torquent per conubia nostra, per inceptos himenaeos. Nam
                      tristique ex at nisl fringilla vestibulum. Nam sit amet
                      laoreet turpis. Vestibulum consequat risus id quam
                      facilisis, at vulputate urna tristique.
                    </p>
                    <div className="article-footer m-b-55">
                      <div className="article-tags">
                        <ul className="tags">
                          <li className="tag">
                            <a href="#">website</a>
                          </li>
                          <li className="tag">
                            <a href="#">onepage</a>
                          </li>
                          <li className="tag">
                            <a href="#">profesional</a>
                          </li>
                        </ul>
                      </div>
                      <div className="article-share">
                        <span>SHARE</span>
                        <ul className="h-list">
                          <li className="social-item">
                            <a
                              className="fa fa-facebook"
                              href="#"
                              data-toggle="tooltip"
                              title="Facebook"
                            />
                          </li>
                          <li className="social-item">
                            <a
                              className="fa fa-twitter"
                              href="#"
                              data-toggle="tooltip"
                              title="Twitter"
                            />
                          </li>
                          <li className="social-item">
                            <a
                              className="fa fa-google-plus"
                              href="#"
                              data-toggle="tooltip"
                              title="Google Plus"
                            />
                          </li>
                          <li className="social-item">
                            <a
                              className="fa fa-linkedin"
                              href="#"
                              data-toggle="tooltip"
                              title="Linkedin"
                            />
                          </li>
                          <li className="social-item">
                            <a
                              className="fa fa-plus"
                              href="#"
                              data-toggle="tooltip"
                              title="More"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="article-author m-b-60">
                      <div className="author-box">
                        <div className="avatar">
                          <a href="#">
                            <img src="images/avatar-04.jpg" alt="post author" />
                          </a>
                        </div>
                        <div className="content">
                          <h4 className="author-title">
                            <a className="text-black" href="#">
                              John Smith
                            </a>
                          </h4>
                          <p className="author-content">
                            Fusce interdum lorem quis sodales porttitor. Cras
                            nulla massa, dictum sit amet metus ac, convallis
                            posuere tortor. Donec non mi sit amet odio maximus
                            commodo a mattis enim.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="article-comments m-b-70">
                      <h4 className="block-title">3 Comments</h4>
                      <ul className="comments-list">
                        <li className="comments-has-child">
                          <div className="comments-item">
                            <div className="author-image">
                              <a href="#">
                                <img
                                  src="images/avatar-01.jpg"
                                  alt="author image"
                                />
                              </a>
                            </div>
                            <div className="comment-content">
                              <p className="content">
                                Phasellus rutrum sollicitudin nisl, at egestas
                                tortor sagittis eget. Nulla tincidunt felis
                                metus, in vehicula lectus porta a. Sed felis
                                erat, sodales in turpis eu.
                              </p>
                              <p className="author-info">
                                <a className="name" href="#">
                                  ARTHUR REYES
                                </a>
                                <span>- JUNE 25, 2018</span>
                                <a className="reply-link" href="#">
                                  <i className="fa fa-share" />
                                  <span>REPLY</span>
                                </a>
                              </p>
                            </div>
                          </div>
                          <ul className="comments-child-list">
                            <li>
                              <div className="comments-item">
                                <div className="author-image">
                                  <a href="#">
                                    <img
                                      src="images/avatar-03.jpg"
                                      alt="author image"
                                    />
                                  </a>
                                </div>
                                <div className="comment-content">
                                  <p className="content">
                                    Nullam ipsum urna, dapibus sed justo sed,
                                    imperdiet malesuada commodo
                                  </p>
                                  <p className="author-info">
                                    <a className="name" href="#">
                                      SHIRLEY CAMPBELL
                                    </a>
                                    <span>- JUNE 25, 2018</span>
                                    <a className="reply-link" href="#">
                                      <i className="fa fa-share" />
                                      <span>REPLY</span>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div className="comments-item">
                            <div className="author-image">
                              <a href="#">
                                <img
                                  src="images/avatar-02.jpg"
                                  alt="author image"
                                />
                              </a>
                            </div>
                            <div className="comment-content">
                              <p className="content">
                                Donec sollicitudin nisi sed eros elementum, non
                                vestibulum quam convallis. Curabitur bibendum
                                magna at nisl hendrerit, et tempus metus
                                facilisis. Praesent augue tellus, euismod id
                                posuere eget, gravida id dolor
                              </p>
                              <p className="author-info">
                                <a className="name" href="#">
                                  SARA ELLIS
                                </a>
                                <span>- MAY 25, 2018</span>
                                <a className="reply-link" href="#">
                                  <i className="fa fa-share" />
                                  <span>REPLY</span>
                                </a>
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="article-leave-comment">
                      <h4 className="block-title">Leave a Comment</h4>
                      <p className="text-block">
                        You must be logged in to post a comment.
                      </p>
                      <div className="row no-gutters">
                        <div className="col-12 input-item m-b-10">
                          <textarea
                            className="au-input au-input-text"
                            placeholder="COMMENT..."
                            style={{ height: 150 }}
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters au-input-group">
                        <div className="col-lg-4 input-item">
                          <input
                            className="au-input"
                            type="text"
                            placeholder="NAME *"
                          />
                        </div>
                        <div className="col-lg-4 input-item">
                          <input
                            className="au-input"
                            type="text"
                            placeholder="EMAIL *"
                          />
                        </div>
                        <div className="col-lg-4 input-item">
                          <input
                            className="au-input"
                            type="text"
                            placeholder="WEBSITE"
                          />
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <div className="col-12 input-item m-t-25">
                          <input
                            className="au-btn au-btn-pill au-btn-primary"
                            type="submit"
                            defaultValue="POST COMMENT"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END BLOG MODAL*/}
        {/* Map start*/}
        <div className="section section-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7020.038690185731!2d76.94270617689855!3d28.388483668706556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3dbf21ccf71d%3A0x5c83663e33b80ab!2sSector%2081%2C%20Gurugram%2C%20Haryana!5e0!3m2!1sen!2sin!4v1638755772400!5m2!1sen!2sin"
            width="full"
            height={550}
            style={{ border: 0, width: "100%" }}
            allowFullScreen
            loading="lazy"
          />
        </div>
        {/* Map end*/}

        {/* Contact start*/}
        <section
          className="section section-contact-form bg-gray-50 p-t-90 p-b-100"
          id="contact"
        >
          <div className="container">
            <h3 className="section-heading section-heading--center m-b-25">
              Any Questions?
            </h3>
            <p
              className="my-caption"
              style={{ textAlign: "center", color: "black" }}
            >
              Send us your queries, and we'll revert back at the earliest.
            </p>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="messages" id="status" />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <form
                  className="section-form-group m-b-50"
                  name="contact"
                  id="contact-form"
                  role="form"
                  data-toggle="validator"
                  onSubmit={submitRequest}
                >
                  <div className="input-item form-group">
                    <div className="help-block with-errors" />
                    <input
                      className="au-input au-input-pill"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Your Name"
                      required
                      data-error="Name is required."
                    />
                  </div>
                  <div className="input-item form-group">
                    <div className="help-block with-errors" />
                    <input
                      className="au-input au-input-pill"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Your Email"
                      required
                      data-error="Valid email is required."
                    />
                  </div>
                  <div className="input-item form-group">
                    <div className="help-block with-errors" />
                    <input
                      className="au-input au-input-pill"
                      type="text"
                      id="number"
                      name="number"
                      placeholder="Your Phone no."
                      required
                      data-error="Valid Phone no. is required."
                    />
                  </div>
                  <div className="input-item form-group">
                    <div className="help-block with-errors" />
                    <textarea
                      className="au-input au-input-pill"
                      placeholder="Your Message"
                      id="msg"
                      name="msg"
                      style={{ height: 115 }}
                      required
                      data-error="Please, leave us a message."
                      defaultValue={""}
                    />
                  </div>
                  <div className="input-item form-group">
                    <div className="help-block with-errors" />
                    <input
                      className="au-btn au-btn-pill au-btn-primary"
                      type="submit"
                      id="contactBtn"
                      defaultValue="SUBMIT"
                    />
                  </div>
                </form>
                <div className="row no-gutters">
                  <div className="col-md-6">
                    <div className="contact-text contact-text-border">
                      <p className="contact-text-title">Lets Talk</p>
                      <p className="contact-text-content">
                        <a href="tel:+919306293768">
                          India Office: +91 9306293768
                        </a>
                        <br />
                        <a href="tel:+971543010024">
                          Dubai Office: +971 54 301 0024
                        </a>
                        <br />
                        <a href="tel://+971-4-323-5331">+971 4 323 5331</a>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-text">
                      <p className="contact-text-title">Address</p>
                      <p className="contact-text-content">
                        <span>India Office: </span> D051, Sector 81, Gurgaon
                        Haryana-122001 <br />
                        <br />
                        <span>Dubai Office: </span> #3205, AL Manara Tower,
                        Business Bay, Dubai
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Contact end*/}
        {/* Social start*/}
        <div className="section section-socials p-t-50 p-b-50">
          <div className="container">
            <ul className="list-socials">
              <li className="list-item">
                <a
                  className="fa fa-facebook"
                  href="#"
                  data-toggle="tooltip"
                  title="Facebook"
                />
              </li>
              <li className="list-item">
                <a
                  className="fa fa-twitter"
                  href="#"
                  data-toggle="tooltip"
                  title="Twitter"
                />
              </li>
              <li className="list-item">
                <a
                  className="fa fa-linkedin"
                  href="#"
                  data-toggle="tooltip"
                  title="Linkedin"
                />
              </li>
              <li className="list-item">
                <a
                  className="fa fa-instagram"
                  href="#"
                  data-toggle="tooltip"
                  title="Instagram"
                />
              </li>
            </ul>
          </div>
        </div>
        {/* Social end*/}
        {/* Footer start*/}
        <footer className="section section-footer p-t-50 p-b-60">
          <div className="container">
            <p className="footer-text-copy">
              <span>Built with</span>
              <span className="fa fa-heart" />
              <span>in India.</span>
            </p>
            <p className="footer-text-copy">
              Copyright Ⓒ 2021 Riyom International. All Rights Reserved. Created
              by{" "}
              <a
                href="https://www.bhavikprojects.tech/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "blue" }}
              >
                Bhavik Arora
              </a>
            </p>
          </div>
        </footer>
        {/* Footer end*/}
        <a href="#home">
          <div id="up-to-top">
            <i className="fa fa-angle-up" />
          </div>
        </a>
      </div>
    </div>
  );
}

export default Home;
