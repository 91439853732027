import React, { useState, useEffect } from "react";
import logo from "../images/icons/logo.png";
import darkl from "../images/icons/darkl.png";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Aos from "aos";
import "aos/dist/aos.css";
import { db } from "../firebase";
import "../css/font-face.css";
import "../vendor/font-awesome/css/font-awesome.min.css";
import "../vendor/linearicons/css/linearicons.css";
import "../vendor/bootstrap/bootstrap.min.css";
import "../vendor/animate.css/animate.min.css";
import "../vendor/css-hamburgers/hamburgers.min.css";
import "../vendor/slick/slick.css";
import "../vendor/animsition/animsition.min.css";
import "../vendor/lightbox2/css/lightbox.min.css";
import "../css/theme.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";

function Contact() {
  const [show, setShow] = useState(false);
  const [isMModalOpen, setIsMModalOpen] = useState(false);
  const [name, setName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", controlNavbar);
  //   return () => {
  //     window.removeEventListener("scroll", controlNavbar);
  //   };
  // }, []);

  useEffect(() => {
    Aos.init({ duration: 900, delay: 300 });
  }, []);

  const submitRequest = (event) => {
    event.preventDefault();

    db.collection("messages")
      .add({
        name: event.target.name.value,
        number: event.target.number.value,
        email: event.target.email.value,
        message: event.target.msg.value,
        time: new Date(),
      })
      .then((result) => {
        console.log("Message Sent");
        setName(event.target.name.value);
        handleShow()
        // alert(`Hi ${name}! Your Message has been sent successfully. Our team will contact you soon :-)`);
      })
      .catch((err) => alert(err.message));

    // sendEmail(event);

    document.getElementById("contact-form").reset();
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Message Sent!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center mb-8">
            Hi! Your Message has been sent successfully. Our team will contact
            you soon :-)
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <header
        id="header"
        className={`js-header unsticky section-header-scroll z-50`}
      >
        <nav className={`navbar navbar-expand-lg nava-dark`}>
          <div className="container">
            <a className="navbar-logo" href="/">
              {/* <img
                className="logo-light"
                src={logo}
                alt="Snac"
                className={`${!show ? "logo-off" : "hidden lg:block"}`}
              /> */}
              <img className="logo-dark" src={darkl} alt="Snac" />
            </a>
            {/* <button
                className="navbar-toggler hamburger hamburger--slider"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button> */}
            <div className="inline-flex lg:hidden">
              {["top"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={() => setIsMModalOpen(true)}>{anchor}</Button> */}
                  {/* <IoIosMenu
                      size={30}
                      className=" text-yellow-400 opacity-80"
                      onClick={() => setIsMModalOpen(true)}
                    />
                     */}
                  <span className="hamburger-box">
                    <span
                      className="hamburger-inner"
                      onClick={() => setIsMModalOpen(true)}
                    />
                  </span>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={isMModalOpen}
                    onClose={() => setIsMModalOpen(false)}
                  >
                    <div
                      className="row bg-gray-900"
                      style={{ textAlign: "center" }}
                    >
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: "1rem",
                        }}
                      >
                        <a
                          href="/"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          HOME
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/products"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          PRODUCTS
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/about"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          ABOUT US
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".5rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/contact"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          CONTACT
                        </a>
                      </div>
                      <button
                        style={{
                          width: "100%",
                          height: "2.5rem",
                          fontSize: "1.2rem",
                          color: "white",
                        }}
                        onClick={() => setIsMModalOpen(false)}
                        className="bg-blue-500"
                      >
                        Close
                      </button>
                      {/* <Button onClick={() => setIsMModalOpen(false)}>Close</Button> */}
                    </div>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
            <div
              className="navbar-collapse collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    HOME
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/products">
                    PRODUCTS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    ABOUT US
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    CONTACT
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div>
        <section className="pt-8 pb-1 pl-1 pr-1 ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="wrapper">
                  <div className="row no-gutters">
                    <div className="col-md-7">
                      <div className="contact-wrap w-100 p-md-5 p-4">
                        <h3 className="mb-4 text-xl">Contact Us</h3>
                        <div id="form-message-warning" className="mb-4" />
                        <div id="form-message-success" className="mb-4">
                          Your message was sent, thank you!
                        </div>
                        <form
                          onSubmit={submitRequest}
                          id="contact-form"
                          name="contactForm"
                          className="contactForm"
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label" htmlFor="name">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  id="name"
                                  placeholder="Name"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label" htmlFor="email">
                                  Email Address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  id="email"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="label" htmlFor="subject">
                                  Mobile No.
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="number"
                                  id="subject"
                                  placeholder="Mobile no."
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="label" htmlFor="#">
                                  Message
                                </label>
                                <textarea
                                  name="message"
                                  className="form-control"
                                  id="msg"
                                  cols={30}
                                  rows={4}
                                  placeholder="Message"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="submit"
                                  defaultValue="Send Message"
                                  className="btn btn-primary"
                                />
                                <div className="submitting" />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-5 d-flex align-items-stretch">
                      <div
                        className="info-wrap w-100 p-5 img"
                        style={{
                          backgroundImage:
                            "url(https://i.ibb.co/xXzp2hk/00-3-8-Major-Problems-with-the-US-Healthcare-System-Today-hero.png)",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-4">
                      <div className="dbox w-100 text-center">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-map-marker" />
                        </div>
                        <div className="text">
                          <p>
                            <span>Address</span>
                            <br />
                            <span>India Office:</span> D051, Sector 81, Gurgaon
                            Haryana-122001
                            <br />
                            <span>Dubai Office:</span> #3205, AL Manara Tower,
                            Business Bay, Dubai
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="dbox w-100 text-center">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-phone" />
                        </div>
                        <div className="text">
                          <p>
                            <span>Phone</span> <br />
                            <span>India Office: </span>
                            <a href="tel://+919306293768">+919306293768</a>
                            <br />
                            <br />
                            <span>Dubai Office: </span>
                            <a href="tel://+971543010024">+971 54 301 0024</a><br />
                            <a href="tel://+971-4-323-5331">+971 4 323 5331</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="dbox w-100 text-center">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-paper-plane" />
                        </div>
                        <div className="text">
                          <p>
                            <span>Email:</span>{" "}
                            <a href="mailto:care@riyominternational.com">
                              care@riyominternational.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Contact;