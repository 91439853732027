import React, { useState, useEffect } from "react";
import logo from "../images/icons/logo.png";
import darkl from "../images/icons/darkl.png";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Aos from "aos";
import "aos/dist/aos.css";
import { db } from "../firebase";
import "../css/font-face.css";
import "../vendor/font-awesome/css/font-awesome.min.css";
import "../vendor/linearicons/css/linearicons.css";
import "../vendor/bootstrap/bootstrap.min.css";
import "../vendor/animate.css/animate.min.css";
import "../vendor/css-hamburgers/hamburgers.min.css";
import "../vendor/slick/slick.css";
import "../vendor/animsition/animsition.min.css";
import "../vendor/lightbox2/css/lightbox.min.css";
import "../css/theme.css";

function Products() {
  const [isMModalOpen, setIsMModalOpen] = useState(false);
  return (
    <div>
      <header
        id="header"
        className={`js-header unsticky section-header-scroll z-50`}
      >
        <nav className={`navbar navbar-expand-lg nava-dark`}>
          <div className="container">
            <a className="navbar-logo" href="/">
              {/* <img
                className="logo-light"
                src={logo}
                alt="Snac"
                className={`${!show ? "logo-off" : "hidden lg:block"}`}
              /> */}
              <img className="logo-dark" src={darkl} alt="Snac" />
            </a>
            {/* <button
                className="navbar-toggler hamburger hamburger--slider"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button> */}
            <div className="inline-flex lg:hidden">
              {["top"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={() => setIsMModalOpen(true)}>{anchor}</Button> */}
                  {/* <IoIosMenu
                      size={30}
                      className=" text-yellow-400 opacity-80"
                      onClick={() => setIsMModalOpen(true)}
                    />
                     */}
                  <span className="hamburger-box">
                    <span
                      className="hamburger-inner"
                      onClick={() => setIsMModalOpen(true)}
                    />
                  </span>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={isMModalOpen}
                    onClose={() => setIsMModalOpen(false)}
                  >
                    <div
                      className="row bg-gray-900"
                      style={{ textAlign: "center" }}
                    >
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: "1rem",
                        }}
                      >
                        <a
                          href="/"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          HOME
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/products"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          PRODUCTS
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/about"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          ABOUT US
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".5rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/contact"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          CONTACT
                        </a>
                      </div>
                      <button
                        style={{
                          width: "100%",
                          height: "2.5rem",
                          fontSize: "1.2rem",
                          color: "white",
                        }}
                        onClick={() => setIsMModalOpen(false)}
                        className="bg-blue-500"
                      >
                        Close
                      </button>
                      {/* <Button onClick={() => setIsMModalOpen(false)}>Close</Button> */}
                    </div>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
            <div
              className="navbar-collapse collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    HOME
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/products">
                    PRODUCTS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    ABOUT US
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    CONTACT
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <section className="text-gray-600 body-font">
        <div className="container px-1 md:px-5 pb-18 pt-12 mx-auto">
          <div className="flex flex-wrap w-full flex-col items-center text-center">
            <h1 className="sm:text-4xl text-2xl font-medium title-font  text-gray-900">
              Products Categories
            </h1>
          </div>
        </div>
        <div className="container px-2.5 py-24 pt-8 mx-auto">
          <div className="flex flex-col">
            <div className="h-1 bg-gray-200 rounded overflow-hidden">
              <div className="w-56 h-full bg-indigo-500 mx-auto" />
            </div>
            <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
              {/* <h1 className="sm:w-2/5 text-gray-900 font-medium title-font text-3xl mb-2 sm:mb-0 ">
                Product Categories-
              </h1> */}
              <p className="leading-relaxed text-base sm:pl-10 pl-0 mx-auto">
                We have divided our product range into three different
                categories based on their utilities &amp; materials.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6 transition duration-150 border-t-4 hover:border-2 shadow-indigo-300 border-r-4 hover:shadow-2xl">
              <a href="/products/gloves" className="hover:text-gray-900">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img
                    alt="content"
                    className="object-cover object-center h-full w-full shadow-lg "
                    src="https://i.ibb.co/2hfLvfF/New-Project-3.jpg"
                  />
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
                  Gloves for Hand Protection
                </h2>
                <p className="text-base leading-relaxed mt-2">
                  The objective of our company is to offer complete hand
                  protection under one roof, we offer different types of gloves
                  sutied for different application. We import gloves made of
                  different materials like Latex, Nitrile, Vinyl, Plastic,
                  Neoprene, Doted, etc.
                </p>
                {/* <a className="text-indigo-500 inline-flex items-center mt-3">
                  Learn More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </a> */}
              </a>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6 transition border-t-4 duration-150 hover:border-2  shadow-indigo-300 hover:shadow-2xl ">
              <a href="/products/non-wovens" className="hover:text-gray-900">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img
                    alt="content"
                    className="object-cover object-center h-full w-full"
                    src="https://i.ibb.co/DgC8jzH/New-Project-5.jpg"
                  />
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
                  Non-Woven Products
                </h2>
                <p className="text-base leading-relaxed mt-2">
                  In the modern healthcare setting, usage of non-woven
                  disposable products has an impact on the safety and hygiene
                  front. Considering the currect scenario and for the
                  convenience of our customers, Riyom International has ventured
                  into manufacturing of non-woven disposable products since
                  2004.
                </p>
                {/* <a className="text-indigo-500 inline-flex items-center mt-3">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a> */}
              </a>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 border-l-4 mb-6  border-t-4 transition duration-150 hover:border-2 shadow-indigo-300 hover:shadow-2xl">
              <a
                href="/products/plastic-products"
                className="hover:text-gray-900"
              >
                <div className="rounded-lg h-64 overflow-hidden">
                  <img
                    alt="content"
                    className="object-cover object-center h-full w-full"
                    src="https://i.ibb.co/X46gbdq/New-Project-6.jpg"
                  />
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
                  Plastic Products
                </h2>
                <p className="text-base leading-relaxed mt-2">
                  We also manufacture Plastic products to fulfill the needs of
                  our customers. We are always ready to serve our clients
                  efficiently.
                </p>
                {/* <a className="text-indigo-500 inline-flex items-center mt-3">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a> */}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Products;
