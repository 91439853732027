import React, { useState, useEffect } from "react";
import logo from "../images/icons/logo.png";
import darkl from "../images/icons/darkl.png";
import about from "./assets/img/about/about.png";
import about2 from "./assets/img/about/about2.png";
import aboutl from "./assets/img/about/aboutl.svg";
import aboutr from "./assets/img/about/aboutr.svg";
import leftd from "./assets/img/about/leftd.svg";
import rightd from "./assets/img/about/rightd.svg";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Aos from "aos";
import "aos/dist/aos.css";
import { db } from "../firebase";
import "../css/font-face.css";
import "../vendor/font-awesome/css/font-awesome.min.css";
import "../vendor/linearicons/css/linearicons.css";
import "../vendor/bootstrap/bootstrap.min.css";
import "../vendor/animate.css/animate.min.css";
import "../vendor/css-hamburgers/hamburgers.min.css";
import "../vendor/slick/slick.css";
import "../vendor/animsition/animsition.min.css";
import "../vendor/lightbox2/css/lightbox.min.css";
import "../css/theme.css";
import "./assets/css/animate.css";
import "./assets/css/lineicons.css";
import "./assets/css/main.css";

function About() {
  const [show, setShow] = useState(true);
  const [isMModalOpen, setIsMModalOpen] = useState(false);
  const [name, setName] = useState("");

  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  var scrollEventHandler = function () {
    window.scroll(0, window.pageYOffset);
  };

  window.addEventListener("scroll", scrollEventHandler, false);

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  useEffect(() => {
    Aos.init({ duration: 900, delay: 300 });
  }, []);

  return (
    <div>
      <header
        id="header"
        className={`js-header unsticky section-header-scroll z-50`}
      >
        <nav className={`navbar navbar-expand-lg nava-dark`}>
          <div className="container">
            <a className="navbar-logo" href="/">
              {/* <img
                className="logo-light"
                src={logo}
                alt="Snac"
                className={`${!show ? "logo-off" : "hidden lg:block"}`}
              /> */}
              <img className="logo-dark" src={darkl} alt="Snac" />
            </a>
            {/* <button
                className="navbar-toggler hamburger hamburger--slider"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button> */}
            <div className="inline-flex lg:hidden">
              {["top"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={() => setIsMModalOpen(true)}>{anchor}</Button> */}
                  {/* <IoIosMenu
                      size={30}
                      className=" text-yellow-400 opacity-80"
                      onClick={() => setIsMModalOpen(true)}
                    />
                     */}
                  <span className="hamburger-box">
                    <span
                      className="hamburger-inner"
                      onClick={() => setIsMModalOpen(true)}
                    />
                  </span>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={isMModalOpen}
                    onClose={() => setIsMModalOpen(false)}
                  >
                    <div
                      className="row bg-gray-900"
                      style={{ textAlign: "center" }}
                    >
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: "1rem",
                        }}
                      >
                        <a
                          href="/"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          HOME
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/products"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          PRODUCTS
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/about"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          ABOUT US
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".5rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/contact"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          CONTACT
                        </a>
                      </div>
                      <button
                        style={{
                          width: "100%",
                          height: "2.5rem",
                          fontSize: "1.2rem",
                          color: "white",
                        }}
                        onClick={() => setIsMModalOpen(false)}
                        className="bg-blue-500"
                      >
                        Close
                      </button>
                      {/* <Button onClick={() => setIsMModalOpen(false)}>Close</Button> */}
                    </div>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
            <div
              className="navbar-collapse collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    HOME
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/products">
                    PRODUCTS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    ABOUT US
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    CONTACT
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <section className="text-gray-600 body-font">
        <div className="container px-1 md:px-5 pb-18 pt-12 mx-auto">
          <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
            <h1 className="sm:text-4xl text-2xl font-medium title-font mb-3 text-gray-900 border-b-4 ">
              About Us
            </h1>
            <section id="about" className="container about-section pt-1">
              <div className="container p-0">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-6 p-0">
                    <div className="about-img hidden lg:inline-block">
                      <img
                        src="https://i.ibb.co/30ftGXb/New-Project-1.png"
                        alt
                        className="w-100"
                      />
                      <img src={aboutl} alt className="shape shape-1" />
                      <img src={leftd} alt className="shape shape-2 -mt-48" />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <div className="about-content">
                      <div className="section-title mb-30">
                        <h2
                          className="mb-25 wow fadeInUp font-bold md:text-3xl"
                          data-wow-delay=".2s"
                        >
                          Our Mission
                        </h2>
                        <p
                          className="wow fadeInUp text-left lg:text-md xl:text-lg"
                          data-wow-delay=".4s"
                        >
                          Riyom international was established with the sole
                          objective to provide quality disposable hygiene
                          products and world class services to Medical
                          Professional, Industrial foreman, Pharmaceutical
                          Companies &amp; Hotel Industry.
                          <br /> <br /> After several years of endeavor, Riyom
                          international has evolved into a conglomerate business
                          focusing in the customer hygiene products through it's
                          brand "GLOVID".
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="about" className="container about-section pt-10">
              <div className="container p-0">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-6 p-0">
                    <div className="about-content">
                      <div className="section-title mb-30">
                        <h2
                          className="mb-25 wow fadeInUp font-bold md:text-3xl"
                          data-wow-delay=".2s"
                        >
                          We Care for you!
                        </h2>
                        <p
                          className="wow fadeInUp text-left"
                          data-wow-delay=".4s"
                        >
                          Driven by customer oriented principles Riyom
                          International reshapes its range of products according
                          to demands and put great Emphasis on on-time delivery.
                          <br /> <br /> Riyom international is one of the
                          largest importers of different types of gloves and ond
                          of the top manufacturers of non woven &amp; plastic
                          disposable products in India.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 ">
                    <div className="about-img-2">
                      <img
                        src="https://i.ibb.co/SKFsbCY/about-3.png"
                        alt
                        className="w-100"
                      />
                      <img src={aboutr} alt className="shape shape-1" />
                      <img src={rightd} alt className="shape shape-2" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <h3 className="section-heading section-heading--center section-heading--dark m-b-50">
              Products
            </h3> */}
          </div>

          <div className="flex flex-wrap -m-4">
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                  Best Machinery
                </h2>
                <p className="leading-relaxed text-base">
                  We have state of the art machinery to Produce disposable
                  hygiene products.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                  Quality Materials
                </h2>
                <p className="leading-relaxed text-base">
                  We source the raw material directly from the manufacturer to
                  keep control on quality of raw material &amp; manufacture the
                  product in clean room to ensure the reliablility of product.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                    <circle cx={12} cy={7} r={4} />
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                  Quality Checks
                </h2>
                <p className="leading-relaxed text-base">
                  We have fully dedicated quality control department for
                  inspection of raw material, work in process &amp; finished
                  products to ensure that our customers get products as
                  confirmed.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7" />
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                  Efficient Service
                </h2>
                <p className="leading-relaxed text-base">
                  Our team of marketing persons are trained in specific ways so
                  as to ensure that all our customer needs are looked into
                  efficiency.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z" />
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                  Hygiene is the Priority
                </h2>
                <p className="leading-relaxed text-base">
                  All the employees (Technical &amp; Marketing) are trained
                  keeping in mind the importance of hygiene and safety.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <circle cx={6} cy={6} r={3} />
                    <circle cx={6} cy={18} r={3} />
                    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12" />
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                  As you want it!
                </h2>
                <p className="leading-relaxed text-base">
                  Our team of design and development staff can manufacture
                  products to suit your requirement.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap w-full mb-10 mt-10 flex-col items-center text-center">
              <p className="lg:w-3/4 w-full leading-relaxed text-gray-500 ">
                All the above points ensure good quality products to our
                customers at competitive price, which has helped the company to
                achieve ISO 9001-2008 CE certification.
              </p>
            </div>
            <a
              href="/products"
              className="main-btn btn-hover border-btn wow fadeInUp mx-auto mb-10"
              data-wow-delay=".6s"
            >
              Check out the Products!
            </a>
          </div>
          {/* <button className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
            Button
          </button> */}
        </div>
      </section>
    </div>
  );
}

export default About;
