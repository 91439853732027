import React, { useState, useEffect } from "react";
import logo from "../images/icons/logo.png";
import darkl from "../images/icons/darkl.png";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Aos from "aos";
import "aos/dist/aos.css";
import { db } from "../firebase";
import "../css/font-face.css";
import "../vendor/font-awesome/css/font-awesome.min.css";
import "../vendor/linearicons/css/linearicons.css";
import "../vendor/bootstrap/bootstrap.min.css";
import "../vendor/animate.css/animate.min.css";
import "../vendor/css-hamburgers/hamburgers.min.css";
import "../vendor/slick/slick.css";
import "../vendor/animsition/animsition.min.css";
import "../vendor/lightbox2/css/lightbox.min.css";
import "../css/theme.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";

function Solo({ contents }) {
  const [isMModalOpen, setIsMModalOpen] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const submitRequest = (event) => {
    event.preventDefault();

    db.collection("quoteRequests")
      .add({
        name: event.target.name.value,
        number: event.target.number.value,
        email: event.target.email.value,
        message: event.target.message.value,
        time: new Date(),
      })
      .then((result) => {
        console.log("Message Sent");
        alert(
          `Hi! Your request has been sent successfully. Our team will revert back soon :-)`
        );
        handleClose();
      })
      .catch((err) => alert(err.message));

    // sendEmail(event);

    document.getElementById("quote-form").reset();
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Request a Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center mb-8">
            Please fill the following details to submit your quote request.
          </p>
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            <form
              onSubmit={submitRequest}
              id="quote-form"
              name="quoteForm"
            >
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-full md:w-1/2">
                <div className="relative">
                  <label
                    htmlFor="name"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full md:w-1/2">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Contact Number
                  </label>
                  <input
                    type="text"
                    id="number"
                    name="number"
                    placeholder="Contact Number"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    htmlFor="message"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Describe your requirement
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-2 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    defaultValue={""}
                    placeholder="Product Name, Quantity, Usage, extra information if any"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <button type="submit" className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                  Submit
                </button>
              </div>
            </div>
            </form>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
      <header
        id="header"
        className={`js-header unsticky section-header-scroll z-50`}
      >
        <nav className={`navbar navbar-expand-lg nava-dark`}>
          <div className="container">
            <a className="navbar-logo" href="/">
              {/* <img
                className="logo-light"
                src={logo}
                alt="Snac"
                className={`${!show ? "logo-off" : "hidden lg:block"}`}
              /> */}
              <img className="logo-dark" src={darkl} alt="Snac" />
            </a>
            {/* <button
                className="navbar-toggler hamburger hamburger--slider"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button> */}
            <div className="inline-flex lg:hidden">
              {["top"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={() => setIsMModalOpen(true)}>{anchor}</Button> */}
                  {/* <IoIosMenu
                      size={30}
                      className=" text-yellow-400 opacity-80"
                      onClick={() => setIsMModalOpen(true)}
                    />
                     */}
                  <span className="hamburger-box">
                    <span
                      className="hamburger-inner"
                      onClick={() => setIsMModalOpen(true)}
                    />
                  </span>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={isMModalOpen}
                    onClose={() => setIsMModalOpen(false)}
                  >
                    <div
                      className="row bg-gray-900"
                      style={{ textAlign: "center" }}
                    >
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: "1rem",
                        }}
                      >
                        <a
                          href="/"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          HOME
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/products"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          PRODUCTS
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/about"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          ABOUT US
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".5rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/contact"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          CONTACT
                        </a>
                      </div>
                      <button
                        style={{
                          width: "100%",
                          height: "2.5rem",
                          fontSize: "1.2rem",
                          color: "white",
                        }}
                        onClick={() => setIsMModalOpen(false)}
                        className="bg-blue-500"
                      >
                        Close
                      </button>
                      {/* <Button onClick={() => setIsMModalOpen(false)}>Close</Button> */}
                    </div>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
            <div
              className="navbar-collapse collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    HOME
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/products">
                    PRODUCTS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    ABOUT US
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    CONTACT
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-2 text-left sm:text-center py-24 mx-auto">
          <div className="lg:w-4/5 mx-auto flex flex-wrap">
            <img
              alt="ecommerce"
              className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              src={contents.spic}
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h2 className="text-sm title-font text-gray-500 tracking-widest mb-2">
                Material &bull; {contents.material}
              </h2>
              <h1 className="text-gray-900 text-3xl title-font font-medium mb-4">
                {contents.name}
              </h1>

              <p className="leading-relaxed">{contents.description}</p>
              <div className=" mt-6 items-center pb-2 border-b-2 border-gray-200 mb-5">
                <div className="space-y-1 mb-2">
                  <p className="mr-3 font-bold">{contents.p1statement}:</p>
                  <span>{contents.p1value}</span>
                </div>
                <div className="space-y-1">
                  <p className="mr-3 font-bold">{contents.p2statement}</p>
                  <span>{contents.p2value}</span>
                </div>
              </div>
              <div className="flex space-x-2 justify-center flex-grow">
                {/* <span className="title-font font-medium text-2xl text-gray-900">
                  $58.00
                </span> */}
                <button
                  onClick={handleShow}
                  className="flex  text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
                >
                  Get a Quote
                </button>
                <a href="/contact">
                  <button className="flex  text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">
                    Contact Us
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Solo;
