import firebase from "firebase/compat/app";
// import "firebase/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCtBjBinBmLGFRPOJnlN2VigNK8MprbOoQ",
  authDomain: "riyom-6189d.firebaseapp.com",
  projectId: "riyom-6189d",
  storageBucket: "riyom-6189d.appspot.com",
  messagingSenderId: "710128660393",
  appId: "1:710128660393:web:0294f12eb7447c0ecf5eb8",
  measurementId: "G-W70HHBMZF8",
};

let app;
if (firebase.apps.length === 0) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();
}

const db = app.firestore();
// const auth = firebase.auth();
// const storage = firebase.storage();

export { db };