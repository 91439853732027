import React, { useState, useEffect } from "react";
import logo from "../images/icons/logo.png";
import darkl from "../images/icons/darkl.png";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Aos from "aos";
import "aos/dist/aos.css";
import { db } from "../firebase";
import "../css/font-face.css";
import "../vendor/font-awesome/css/font-awesome.min.css";
import "../vendor/linearicons/css/linearicons.css";
import "../vendor/bootstrap/bootstrap.min.css";
import "../vendor/animate.css/animate.min.css";
import "../vendor/css-hamburgers/hamburgers.min.css";
import "../vendor/slick/slick.css";
import "../vendor/animsition/animsition.min.css";
import "../vendor/lightbox2/css/lightbox.min.css";
import "../css/theme.css";

function Gloves() {
  const [isMModalOpen, setIsMModalOpen] = useState(false);
  const [stories, setStories] = useState([]);
  useEffect(() => {
    db.collection("gloves").onSnapshot((snapshot) =>
      setStories(snapshot.docs.map((doc) => doc.data()))
    );
  }, []);
  return (
    <div>
      <header
        id="header"
        className={`js-header unsticky section-header-scroll z-50`}
      >
        <nav className={`navbar navbar-expand-lg nava-dark`}>
          <div className="container">
            <a className="navbar-logo" href="/">
              {/* <img
                className="logo-light"
                src={logo}
                alt="Snac"
                className={`${!show ? "logo-off" : "hidden lg:block"}`}
              /> */}
              <img className="logo-dark" src={darkl} alt="Snac" />
            </a>
            {/* <button
                className="navbar-toggler hamburger hamburger--slider"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button> */}
            <div className="inline-flex lg:hidden">
              {["top"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={() => setIsMModalOpen(true)}>{anchor}</Button> */}
                  {/* <IoIosMenu
                      size={30}
                      className=" text-yellow-400 opacity-80"
                      onClick={() => setIsMModalOpen(true)}
                    />
                     */}
                  <span className="hamburger-box">
                    <span
                      className="hamburger-inner"
                      onClick={() => setIsMModalOpen(true)}
                    />
                  </span>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={isMModalOpen}
                    onClose={() => setIsMModalOpen(false)}
                  >
                    <div
                      className="row bg-gray-900"
                      style={{ textAlign: "center" }}
                    >
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: "1rem",
                        }}
                      >
                        <a
                          href="/"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          HOME
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/products"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          PRODUCTS
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".2rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/about"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          ABOUT US
                        </a>
                      </div>
                      <div
                        className="col-lg col-md"
                        style={{
                          paddingBottom: ".5rem",
                          paddingTop: ".2rem",
                        }}
                      >
                        <a
                          href="/contact"
                          className="text-gray-300 hover:no-underline hover:text-black"
                          style={{ textDecoration: "none" }}
                        >
                          CONTACT
                        </a>
                      </div>
                      <button
                        style={{
                          width: "100%",
                          height: "2.5rem",
                          fontSize: "1.2rem",
                          color: "white",
                        }}
                        onClick={() => setIsMModalOpen(false)}
                        className="bg-blue-500"
                      >
                        Close
                      </button>
                      {/* <Button onClick={() => setIsMModalOpen(false)}>Close</Button> */}
                    </div>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
            <div
              className="navbar-collapse collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    HOME
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/products">
                    PRODUCTS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    ABOUT US
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    CONTACT
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {/* {stories.map((story) => (
        <div className="py-5 p-0 md:p-5  ">
          {story.name}
        </div>
      ))} */}
      <div className="container px-1 md:px-5 pt-12 mx-auto">
        <div className="flex flex-wrap w-full mb-8 flex-col items-center text-center">
          <h1 className="sm:text-4xl text-2xl font-medium title-font mb-3 text-gray-900 border-b-4 ">
            Hand Protection Gloves
          </h1>
        </div>
      </div>
      <section className="text-gray-600 body-font">
        <div className="container px-2 lg:px-5  mx-auto">
          <div className="flex flex-wrap -m-4">
            {stories.map((story) => (
              <div className="p-4 md:w-1/3">
                <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <a href={`/${story.url}`} className="hover:text-gray-900">
                    <img
                      className="w-full object-cover object-center"
                      src={story.spic}
                      alt="blog"
                    />
                    <div className="p-6">
                      <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                        Material &bull; {story.material}
                      </h2>
                      <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                        {story.name}
                      </h1>
                      {/* <p className="leading-relaxed mb-3">
                        Photo booth fam kinfolk cold-pressed sriracha leggings
                        jianbing microdosing tousled waistcoat.
                      </p> */}
                      <div className="flex items-center flex-wrap ">
                        <a className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                          View
                          <svg
                            className="w-4 h-4 ml-2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14" />
                            <path d="M12 5l7 7-7 7" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Gloves;
